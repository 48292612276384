:root {
  --bodyColor: "#ffffff";
}

body {
  background-color: var(--bodyColor) !important;
}

.home-img{
  margin-top: 50px;
max-height: 700px;
border-radius: 30px;
}

.btn_padding{
  margin-left: 20px;

}

.container_main{
  background-color: white;
  padding-top: 20px;
  padding-bottom: 20px;
}

.container_main_background{
  padding-top: 30px;
  padding-bottom: 30px;
}

.customCard {
  border-radius: 0px !important;
  min-height: 150px;
  border: 2px !important;
}

.customCardParking {
  min-height: 100px;
  border-radius: 0px !important;
  min-height: 50px;
  border: 2px !important;
}

.card-map{
  margin: 50px;
}